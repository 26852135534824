import React, { useCallback, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { toast, Bounce } from 'react-toastify';

import ContactForm from '../../components/ContactForm';
import styles from './ContactUs.module.css';

export default function ContactUs({ contactRef }) {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmitForm = useCallback(
    (e) => {
      e.preventDefault();
      if (name && email && message) {
        if (!executeRecaptcha) {
          toast.error('Execute recaptcha not yet available', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            transition: Bounce,
          });
          return;
        }
        executeRecaptcha('contactFormSubmit').then((gReCaptchaToken) => {
          console.log(gReCaptchaToken);
          submitContactForm();
        });
      }
    },
    // eslint-disable-next-line
    [executeRecaptcha, name, email, message],
  );

  const submitContactForm = async () => {
    const bearer =
      'Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6Ijc3Y2MwZWY0YzcxODFjZjRjMGRjZWY3YjYwYWUyOGNjOTAyMmM3NmIiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJhY2NvdW50cy5nb29nbGUuY29tIiwiYXpwIjoiNjE4MTA0NzA4MDU0LTlyOXMxYzRhbGczNmVybGl1Y2hvOXQ1Mm4zMm42ZGdxLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwiYXVkIjoiNjE4MTA0NzA4MDU0LTlyOXMxYzRhbGczNmVybGl1Y2hvOXQ1Mm4zMm42ZGdxLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwic3ViIjoiMTA0ODUwODc4MTUyMzk5MjkzMjEwIiwiaGQiOiJzYWtld2l6LmNvbSIsImVtYWlsIjoiamFzb24uZXZhbnNAc2FrZXdpei5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiYXRfaGFzaCI6IkpNakl2dWo5V1ZjVkFfcE5DWnRncFEiLCJpYXQiOjE2NjcwODAxMTYsImV4cCI6MTY2NzA4MzcxNiwianRpIjoiNDM4ZWIzYzBlNGVlY2UzNTRmZWEyMTViM2JlNzJhNTRlM2U1N2NhOSJ9.Qz28UVek5fv5eJFjw6PjvATlObjjbkIoz85tjlx4JDSQg9kANMOxTyNA5-7GpxmDtQDYfYnufaSNU-0nErpErENOoXAbo4sTQDhSO5XRqKJ_7KN0yzTcWLEEUAwfZMuzJcyvJrBnyDRoDKkbYB2M21HowkP3_BM-IwvQZpNTTj__3xMbaDERfznyGsG8bd6T2qkPSASvWFgP5WozdW5mdQJkfqG3ObZdM_cXMIgn_C0YHrNb91RuyktPvw_UBCamDo-HOTwmZUPTYrV2CrEXM24GB0usbF0T19K-nMv6hsnffgjxh0muMoDnw_TcjdUtMZoAH4P0XRCWFPpZa4M8Sg';

    fetch(
      'https://northamerica-northeast1-nipponsake-cdd8f.cloudfunctions.net/sendmailer/' +
        '?to=' +
        'jason.evans@sakewiz.com' +
        '&subject=' +
        'Nubeverage Customer Contact' +
        '&body=' +
        `Name: ${name}<br>From: ${email}<br><br>${message}`,
      {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          Authorization: bearer,
          'Access-Control-Allow-Origin': '*',
        },
      },
    )
      .then((response) => {
        toast.success('Message sent!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce,
        });
        setName('');
        setEmail('');
        setMessage('');
      })
      .catch((err) => {
        toast.error(err.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce,
        });
      });
  };

  return (
    <div ref={contactRef} className={`relative ${styles.formArea}`}>
      <div className="app-container mx-auto grid grid-cols-1 md:grid-cols-2">
        <div className={`block md:hidden ${styles.formBg}`}></div>
        <form className={styles.form} onSubmit={handleSubmitForm}>
          <ContactForm
            name={name}
            email={email}
            message={message}
            setName={setName}
            setEmail={setEmail}
            setMessage={setMessage}
          />
        </form>
        <div className="hidden md:block"></div>
      </div>
      <div className={`hidden md:block ${styles.formBg}`}></div>
    </div>
  );
}
