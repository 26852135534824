import React from 'react';

import styles from './AboutUs.module.css';

export default function AboutUs({ aboutRef }) {
  return (
    <div
      ref={aboutRef}
      className={`relative pt-[45px] md:pt-[60px] lg:pt-[80px] xl:pt-[100px] 2xl:pt-[120px] ${styles.aboutSpace}`}
    >
      <div className={`absolute ${styles.bgArea}`}></div>
      <div className="app-container mx-auto grid grid-cols-1 md:grid-cols-2">
        <div className="relative h-[231px] xs:h-[320px] sm:h-[400px] md:h-[670px] lg:h-[700px] xl:h-[732px] 2xl:h-[745px]">
          <div className={`absolute ${styles.bgImg}`}></div>
        </div>
        <div className={styles.aboutArea}>
          <p className={styles.aboutTitle}>About Us</p>
          <div className="mb-8">
            <p className={styles.aboutSubTitle}>Our Collection:</p>
            <p className={styles.aboutContent}>
              Our portfolio boasts an eclectic mix of premium beverages,
              including artisanal spirits, boutique wines, and tea from around
              the globe.
            </p>
          </div>
          <div className="mb-8">
            <p className={styles.aboutSubTitle}>Commitment to Quality:</p>
            <p className={styles.aboutContent}>
              Quality is at the heart of everything we do. We rigorously vet
              each product, ensuring it meets our high standards before it
              reaches your glass.
            </p>
          </div>
          <div className="pb-[58px] md:pb-8">
            <p className={styles.aboutSubTitle}>Tailored Service:</p>
            <p className={styles.aboutContent}>
              Our team is committed to providing personalized service, ensuring
              that we meet the specific requirements of restaurants, bars,
              retailers, and private clients.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
