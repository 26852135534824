import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import Menu from './Menu';
import Description from './Description';
import MoreProducts from './MoreProducts';
import ContactUs from './ContactUs';
import Footer from '../../components/Footer';
import products from '../../data/products.json';

export default function ProductDetail() {
  const { pathname } = useLocation();
  const homeRef = useRef(null);
  const premiumRef = useRef(null);
  const contactRef = useRef(null);
  const [activeCard, setActiveCard] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    let cardId = localStorage.getItem('cardId');
    let active_card = products.products.find(
      (product) => product.id === parseInt(cardId),
    );
    if (active_card) {
      setActiveCard(active_card);
    }
  }, [pathname]);

  const scrollToHome = () => {
    homeRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToPremium = () => {
    premiumRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToContact = () => {
    contactRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <Helmet>
        ‍<title>Nubeverage - Product Detail</title>‍
        <meta
          name="description"
          content={
            activeCard?.brandName +
            ' ' +
            activeCard?.subType +
            ' - ' +
            activeCard?.productName
          }
        />
      </Helmet>
      <div className="full-container">
        <Menu
          scrollToHome={scrollToHome}
          scrollToPremium={scrollToPremium}
          scrollToContact={scrollToContact}
        />
        <Description
          homeRef={homeRef}
          scrollToContact={scrollToContact}
          activeCard={activeCard}
        />
        <MoreProducts premiumRef={premiumRef} activeCard={activeCard} />
        <ContactUs contactRef={contactRef} />
        <Footer
          scrollToHome={scrollToHome}
          scrollToPremium={scrollToPremium}
          scrollToAbout={scrollToContact}
        />
      </div>
    </>
  );
}
