import React from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './ProductCard.module.css';

export default function ProductCard({ card }) {
  const navigate = useNavigate();

  return (
    <div className={styles.card}>
      <img
        src={card?.images[0]}
        alt={`product-${card.id}`}
        className="mb-[9px] xs:mb-[14px] sm:mb-[19px] md:mb-[24px] lg:mb-[29px] xl:mb-[33px] 2xl:mb-[36px] mx-auto w-[130px] xs:w-[120px] sm:w-[170px] md:w-[210px] xl:w-[230px] 2xl:w-[249px]"
      />
      <div className="relative px-[9px] xs:px-[10px] sm:px-[11px] md:px-[12px] lg:px-[13px] xl:px-[15px] 2xl:px-[17px] py-[9px] xs:py-[10px] sm:py-[11px] md:py-[12px] lg:py-[13px] xl:py-[14px]">
        <p
          className={`absolute ${styles.status} ${
            card.status
              ? card.status.length > 0
                ? 'block'
                : 'hidden'
              : 'hidden'
          } px-1 sm:px-[9px] py-0.5`}
        >
          {card.status}
        </p>
        <p className={styles.productName}>{card.productName}</p>
        <p className={`my-2.5 ${styles.brandName}`}>{card.brandName}</p>
        <button
          className={`py-1 px-5 ${styles.detailButton}`}
          onClick={() => {
            localStorage.setItem('cardId', card.id);
            navigate(
              `/product/${card.type}/${card.productName
                .replace(/\s/g, '-')
                .toLowerCase()}`,
            );
          }}
        >
          See detail
        </button>
      </div>
    </div>
  );
}
