import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './Menu.module.css';

export default function Menu({
  scrollToHome,
  scrollToPremium,
  scrollToContact,
}) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleToggleMenu = () => {
    setOpen(!open);
  };

  return (
    <div
      className="relative"
      onClick={() => {
        if (open) {
          setOpen(false);
        }
      }}
    >
      <div className="app-container mx-auto flex pt-4">
        <div className={`${styles.topBg}`}></div>
        <button
          className="block sm:hidden absolute z-10 transition-all w-8 max-w-8 h-8 max-h-8 rounded-lg hover:shadow-lg focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none mt-[5px] ml-[23px]"
          type="button"
          onClick={handleToggleMenu}
        ></button>
        <img src="/icons/menu.svg" alt="menu" className="pl-[5px]" />
        <img
          src="/icons/logo.svg"
          alt="logo"
          className="mx-auto sm:ml-[30px] md:ml-[99px] sm:mr-[71px] z-10 relative w-[76px] sm:w-[145px] cursor-pointer"
          onClick={() => navigate('/')}
        />
        <ul className="hidden sm:flex list-none justify-center items-center z-10 relative">
          <li
            className={`${styles.menuItem} mr-8`}
            onClick={() => {
              setOpen(false);
              scrollToHome();
            }}
          >
            Home
          </li>
          <li
            className={`${styles.menuItem} mr-8`}
            onClick={() => {
              setOpen(false);
              scrollToPremium();
            }}
          >
            Other Premium Drinks
          </li>
          <li
            className={styles.menuItem}
            onClick={() => {
              setOpen(false);
              scrollToContact();
            }}
          >
            Contact Us
          </li>
        </ul>

        <div
          className={`absolute top-14 z-20 ml-4 bg-white rounded-lg shadow w-44 dark:bg-gray-700 ${
            open ? 'block sm:hidden' : 'hidden'
          }`}
        >
          <ul className="px-2 py-2 text-sm text-gray-700 dark:text-gray-200">
            <li
              className={styles.menuItem}
              onClick={() => {
                setOpen(false);
                scrollToHome();
              }}
            >
              Home
            </li>
            <li
              className={styles.menuItem}
              onClick={() => {
                setOpen(false);
                scrollToPremium();
              }}
            >
              Other Premium Drinks
            </li>
            <li
              className={styles.menuItem}
              onClick={() => {
                setOpen(false);
                scrollToContact();
              }}
            >
              Contact Us
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
