import React from 'react';

import styles from './CollaborationRestaurant.module.css';

export default function CollaborationRestaurant() {
  return (
    <div className={styles.collaborationSpace}>
      <div className="app-container mx-auto">
        <p className={styles.title}>Restaurant Customers</p>
        <img
          src="/images/michelin-star.png"
          alt="michelin-star"
          className="w-[101px] sm:w-[151px] mx-auto sm:pt-[12px] sm:pb-[12px]"
        />
        <div className="flex justify-center mt-[35px] sm:mt-[41px]">
          <div>
            <img
              src="/images/aburi.png"
              alt="aburi"
              className="w-[120px] sm:w-[165px] md:w-[210px] lg:w-[255px] xl:w-[308px] h-[120px] sm:h-[165px] md:h-[210px] lg:h-[255px] xl:h-[308px]"
            />
          </div>
          <div>
            <img
              src="/images/enigma.png"
              alt="enigma"
              className="mx-[7px] xs:mx-[20px] sm:mx-[30px] md:mx-[40px] lg:mx-[90px] xl:mx-[130px] 2xl:mx-[159px] w-[120px] sm:w-[165px] md:w-[210px] lg:w-[255px] xl:w-[308px] h-[120px] sm:h-[165px] md:h-[210px] lg:h-[255px] xl:h-[308px]"
            />
          </div>
          <div>
            <img
              src="/images/sushi.png"
              alt="sushi"
              className="w-[120px] sm:w-[165px] md:w-[210px] lg:w-[255px] xl:w-[308px] h-[120px] sm:h-[165px] md:h-[210px] lg:h-[255px] xl:h-[308px]"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
