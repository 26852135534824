import React from 'react';

import styles from './Intro.module.css';

export default function Intro({ homeRef, scrollToContact }) {
  return (
    <div className={styles.introArea}>
      <div ref={homeRef} className="app-container mx-auto">
        <div className="relative z-10 pt-[30px] sm:pt-[108px] mx-0 sm:mx-[208px]">
          <div className={styles.topTitle}>Premium Beverages</div>
          <div className={`${styles.middleTitle} mb-0 sm:mb-[19px]`}>
            Exclusively
          </div>
          <div className={`${styles.middleTitle} mb-[19px] sm:mb-0`}>
            Original
          </div>
          <div
            className={`${styles.bottomTitle} w-40 sm:w-full mb-[29px] sm:ml-2 py-0 sm:py-[20.5px]`}
          >
            World-Class Selection
          </div>
          <button onClick={scrollToContact} className={styles.contactButton}>
            Find Out More
          </button>
        </div>
      </div>
    </div>
  );
}
