import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import styles from './Footer.module.css';

export default function Footer({
  scrollToHome,
  scrollToPremium,
  scrollToAbout,
}) {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className={`flex justify-center ${styles.footerArea}`}>
      <div className="app-container pt-[27px] pb-[51px] pl-[65px] pr-[41px] sm:px-0 sm:py-[86.5px] ">
        <div className="flex justify-center">
          <img
            src="/icons/logo.svg"
            alt="logo"
            className="h-[45.2px] sm:h-[95px] md:h-[113px] w-[78px] sm:w-[135px] md:w-[195px] mr-[54px] sm:mr-0 cursor-pointer"
            onClick={() => navigate('/')}
          />
          <div className="hidden sm:block sm:mx-[60px] md:mx-[80px] lg:mx-[115px] xl:mx-[150px] 2xl:mx-[180px]">
            <p className={styles.title}>Footer Menu</p>
            <p
              className={`mb-4 cursor-pointer ${styles.content}`}
              onClick={scrollToHome}
            >
              Home
            </p>
            <p
              className={`mb-4 cursor-pointer ${styles.content}`}
              onClick={scrollToPremium}
            >
              {location.pathname.includes('/')
                ? 'Our Premium Drinks'
                : location.pathname.includes('/products')
                ? 'Other Premium Drinks'
                : 'Our Premium Selection'}
            </p>
            <p
              className={`cursor-pointer ${styles.content}`}
              onClick={scrollToAbout}
            >
              {location.pathname.includes('/') ? 'About us' : 'Contact us'}
            </p>
          </div>
          <div className="hidden sm:block">
            <p className={styles.title}>Get in Touch</p>
            <p className={`mb-4 ${styles.content}`}>
              Email:orders@nubeverage.ca
            </p>
            <p className={styles.content}>Tel: 123-456-7890</p>
          </div>
          <div className="block sm:hidden">
            <p className={styles.title}>Footer Menu</p>
            <p
              className={`mb-3 cursor-pointer ${styles.content}`}
              onClick={scrollToHome}
            >
              Home
            </p>
            <p
              className={`mb-3 cursor-pointer ${styles.content}`}
              onClick={scrollToPremium}
            >
              {location.pathname.includes('/')
                ? 'Our Premium Drinks'
                : location.pathname.includes('/products')
                ? 'Other Premium Drinks'
                : 'Our Premium Selection'}
            </p>
            <p
              className={`mb-6 cursor-pointer ${styles.content}`}
              onClick={scrollToAbout}
            >
              {location.pathname.includes('/') ? 'About us' : 'Contact us'}
            </p>
            <p className={styles.title}>Get in Touch</p>
            <p className={`mb-3 ${styles.content}`}>
              Email:orders@nubeverage.ca
            </p>
            <p className={styles.content}>Tel: 123-456-7890</p>
          </div>
        </div>
      </div>
    </div>
  );
}
