import React, { useState, useRef } from 'react';
import parse from 'html-react-parser';

import styles from './Description.module.css';

export default function Description({ homeRef, scrollToContact, activeCard }) {
  const elementRef = useRef(null);
  const [showMore, setShowMore] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);

  const getProductType = (word) => {
    return word?.charAt(0).toUpperCase() + word?.slice(1);
  };

  const handleClickViewMore = () => {
    setShowMore(!showMore);
  };

  const rowCount = activeCard
    ? Math.ceil(activeCard?.description.length / 85) +
      Math.ceil(activeCard?.notes.length / 85)
    : 0;

  return (
    <div className="app-container mx-auto" ref={homeRef}>
      <p className="ml-[23px] xs:ml-[30px] sm:ml-[42px] md:ml-[58px] lg:ml-[74px] xl:ml-[90px] 2xl:ml-[106px] mt-[41px] xs:mt-[46px] sm:mt-[52px] md:mt-[60px] lg:mt-[68px] xl:mt-[76px] 2xl:mt-[84px]">
        Home / Premium {getProductType(activeCard?.type)} /{' '}
        {activeCard?.productName}
      </p>
      <p
        className={`ml-[23px] xs:ml-[30px] sm:ml-[42px] md:ml-0 block md:hidden ${styles.title}`}
      >
        {activeCard?.productName}
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="mt-6">
          <img
            src={activeCard?.images[selectedImage]}
            alt={activeCard?.productName}
            className="mx-auto md:mr-0 md:ml-[20px] lg:ml-[80px] xl:ml-[100px] 2xl:ml-[114px] w-[373px] xs:w-[420px] sm:w-[460px] md:w-[500px] lg:w-[450px] xl:w-[580px] 2xl:w-[621px] h-[363px] xs:h-[400px] sm:h-[440px] md:h-[480px] lg:h-[500px] xl:h-[560px] 2xl:h-[605px]"
          />
          <div className="flex mt-[11px] pl-[10px] xs:pl-[20px] sm:pl-[30px] md:pl-[32px] lg:pl-[90px] xl:pl-[110px] 2xl:pl-[128px]">
            {activeCard?.images.map((img, index) => (
              <img
                key={index}
                src={img}
                alt={img}
                className="mr-3 sm:mr-4 w-[90px] xs:w-[97px] sm:w-[104px] md:w-[111px] lg:w-[118px] xl:w-[124px] 2xl:w-[130px] h-[90px] xs:h-[97px] sm:h-[104px] md:h-[111px] lg:h-[118px] xl:h-[124px] 2xl:h-[130px]"
                onClick={() => setSelectedImage(index)}
              />
            ))}
          </div>
        </div>
        <div
          className="ml-[38px] 2xl:ml-4 mr-[27px] md:mr-0 xl:mr-8 2xl:mr-[109px] md:max-h-[838px] lg:max-h-[677px] xl:max-h-[719px] 2xl:max-h-[770px] overflow-y-auto"
          ref={elementRef}
        >
          <p className={`hidden md:block ${styles.title}`}>
            {activeCard?.productName}
          </p>
          <p className={styles.productLocation}>{activeCard?.brandName}</p>
          <p className={`mb-6 ${styles.subTitle}`}>Description</p>
          <p
            className={`pr-7 lg:pr-16 xl:pr-[108px] 2xl:pr-40 ${styles.content}`}
          >
            {parse(activeCard?.description ? activeCard?.description : '')}
          </p>
          <p className={`my-5 ${styles.content}`}>TASTING NOTES</p>
          <p
            className={`pr-7 lg:pr-16 xl:pr-[108px] 2xl:pr-40 ${styles.content}`}
          >
            {parse(activeCard?.notes ? activeCard?.notes : '')}
          </p>
          {rowCount > 9 ? (
            <p
              className={`mt-4 mb-8 ${showMore ? 'hidden' : 'block'} ${
                styles.viewMore
              }`}
              onClick={handleClickViewMore}
            >
              View More
            </p>
          ) : null}
          <p
            className={`my-[18px] transition-all ${
              rowCount > 9 ? (showMore ? 'block' : 'hidden') : 'block'
            } ${styles.subTitle}`}
          >
            Wanna know our wholesale price and more details?
          </p>
          <p
            className={`pr-7 lg:pr-16 xl:pr-[108px] 2xl:pr-40 mb-8 transition-all ${
              rowCount > 9 ? (showMore ? 'block' : 'hidden') : 'block'
            } ${styles.content}`}
          >
            {parse(activeCard?.details ? activeCard?.details : '')}
          </p>
          {rowCount > 9 ? (
            <p
              className={`mt-4 mb-8 ${showMore ? 'block' : 'hidden'} ${
                styles.viewMore
              }`}
              onClick={handleClickViewMore}
            >
              View Less
            </p>
          ) : null}
          <button onClick={scrollToContact} className={styles.contactButton}>
            Contact Us
          </button>
        </div>
      </div>
    </div>
  );
}
