import React, { useContext, useEffect, useState } from 'react';
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/classic.css';

import { ProductContext } from '../../context/ProductContext';
import ProductCard from '../../components/ProductCard';
import styles from './MainProduct.module.css';
import products from '../../data/products.json';

export default function MainProduct({ productRef }) {
  const { filter, selectFilter } = useContext(ProductContext);
  const [items, setItems] = useState([]);
  const [open, setOpen] = useState(false);
  const [activeSort, setActiveSort] = useState('By Brand');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(12);

  useEffect(() => {
    getProductsByFilter();
    // eslint-disable-next-line
  }, [filter, activeSort]);

  const getProductsByFilter = () => {
    if (filter === 'All') {
      getProductsBySort([...products.products]);
    } else if (filter === 'Tea') {
      getProductsBySort(
        products.products.filter((product) => product.type === 'tea'),
      );
    } else if (filter === 'Juice') {
      getProductsBySort(
        products.products.filter((product) => product.type === 'juice'),
      );
    } else if (filter === 'Alcohol') {
      getProductsBySort(
        products.products.filter((product) => product.type === 'alcohol'),
      );
    }
  };

  const getProductsBySort = (products) => {
    let updatedProducts = [];
    if (activeSort === 'By Brand') {
      updatedProducts = products.sort((a, b) =>
        a.brandName.localeCompare(b.brandName),
      );
    } else if (activeSort === 'By Price') {
      updatedProducts = products.sort((a, b) => a.id - b.id);
    } else if (activeSort === 'By Name') {
      updatedProducts = products.sort((a, b) =>
        a.productName.localeCompare(b.productName),
      );
    }
    setItems(updatedProducts);
  };

  const totalPages = Math.ceil(items.length / itemsPerPage);
  const currentItems = items.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage,
  );

  return (
    <div
      className="app-container mx-auto relative z-10 mb-[30px] xs:mb-[38px] sm:mb-[46px] md:mb-[54px] lg:mb-[62px] xl:mb-[70px] 2xl:mb-[77px]"
      onClick={() => {
        if (open) {
          setOpen(false);
        }
      }}
    >
      <div
        className="mx-4 xs:mx-10 sm:mx-[60px] md:mx-[80px] lg:mx-[100px] xl:mx-[120px] 2xl:mx-[148px] mt-[70px] xs:mt-[90px] sm:mt-[110px] md:mt-[130px] lg:mt-[150px] xl:mt-[170px] 2xl:mt-[200px]"
        ref={productRef}
      >
        <p className={styles.subTitle}>Home / Premium {filter}</p>
        <div className="block md:flex ml-0 md:ml-[7px] mr-0 md:mr-[20px] mb-[36px] xs:mb-[41px] sm:mb-[46px] md:mb-[51px] lg:mb-[56px] xl:mb-[64px] 2xl:mb-[72px]">
          <div className="flex">
            <button
              className={`mr-2 xs:mr-2.5 sm:mr-3 md:mr-3.5 lg:mr-4 xl:mr-[18px] 2xl:mr-[21px] ${
                filter === 'Tea' ? styles.activeBrandButton : styles.brandButton
              }`}
              onClick={() => selectFilter('Tea')}
            >
              Tea
            </button>
            <button
              className={`mr-2 xs:mr-2.5 sm:mr-3 md:mr-3.5 lg:mr-4 xl:mr-[18px] 2xl:mr-[21px] ${
                filter === 'Alcohol'
                  ? styles.activeBrandButton
                  : styles.brandButton
              }`}
              onClick={() => selectFilter('Alcohol')}
            >
              Alcohol
            </button>
            <button
              className={`mr-0 md:mr-[29px] lg:mr-[32px] xl:mr-[34px] 2xl:mr-[36px] ${
                filter === 'Juice'
                  ? styles.activeBrandButton
                  : styles.brandButton
              }`}
              onClick={() => selectFilter('Juice')}
            >
              Juice
            </button>
            <button
              className={`ml-auto md:ml-0 ${styles.viewButton}`}
              onClick={() => selectFilter('All')}
            >
              View All
            </button>
          </div>
          <div className="relative ml-0 md:ml-auto mt-[21px] md:mt-0">
            <button
              type="button"
              className="inline-flex justify-center items-center rounded-md bg-white pl-[18px] pr-[18px] sm:pr-[29px] pt-[3px] xs:pt-[5px] sm:pt-[7px] md:pt-[9px] lg:pt-[11px] pb-[3px] xs:pb-[5px] sm:pb-[7px] md:pb-[9px] lg::pb-[10px] shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={() => setOpen(!open)}
            >
              {activeSort}
              <svg
                className="-mr-1 h-5 w-5 text-gray-400"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            <div
              className={`absolute left-0 top-9 xs:top-10 sm:top-12 z-10 w-28 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${
                open ? 'block' : 'hidden'
              }`}
            >
              <ul className="px-2 py-2 text-sm text-gray-700 dark:text-gray-200">
                <li
                  className={styles.menuItem}
                  onClick={() => {
                    setActiveSort('By Brand');
                    setOpen(false);
                  }}
                >
                  By Brand
                </li>
                <li
                  className={styles.menuItem}
                  onClick={() => {
                    setActiveSort('By Name');
                    setOpen(false);
                  }}
                >
                  By Name
                </li>
              </ul>
            </div>
          </div>
        </div>
        
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-[18px] xs:gap-x-[20px] sm:gap-x-[23px] md:gap-x-[26px] lg:gap-x-[29px] xl:gap-x-[32px] 2xl:gap-x-[35px] gap-y-[21px] xs:gap-y-[26px] sm:gap-y-[31px] md:gap-y-[36px] lg:gap-y-[41px] xl:gap-y-[46px] 2xl:gap-y-[52px]">
          {currentItems.map((card) => (
            <ProductCard key={card.id} card={card} />
          ))}
        </div>
          {/*
        <div className="relative mt-4">
   
          <select
            className="absolute left-0 w-12 xs:w-16 mt-2 block rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            value={itemsPerPage}
            onChange={(e) => {
              setItemsPerPage(Number(e.target.value));
            }}
          >
            {[8, 12, 16].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
            
          </select>
          <ResponsivePagination
            current={currentPage}
            total={totalPages}
            onPageChange={setCurrentPage}
            activeItemClassName="active-page-item"
          />
        </div>*/}
            
        {/* <button
          className={`flex justify-center items-center md:hidden mx-auto mt-[35px] ${styles.morebutton}`}
        >
          View more
        </button> */}
      </div>
    </div>
  );
}
