import React, { useEffect, useState } from 'react';
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/classic.css';

import ProductCard from '../../components/ProductCard';
import styles from './MoreProducts.module.css';
import products from '../../data/products.json';

export default function MoreProducts({ premiumRef, activeCard }) {
  const [moreCards, setMoreCards] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(8);

  useEffect(() => {
    let more_cards = products.products.filter(
      (product) =>
        product.brandName === activeCard?.brandName &&
        product.id !== activeCard?.id,
    );
    setMoreCards(more_cards);
  }, [activeCard, setMoreCards]);

  const totalPages = Math.ceil(moreCards.length / itemsPerPage);
  const currentItems = moreCards.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage,
  );

  return (
    <div
      ref={premiumRef}
      className="app-container mx-auto mt-[57px] xs:mt-[68px] sm:mt-[79px] md:mt-[90px] lg:mt-[101px] xl:mt-[112px] 2xl:mt-[124px] mb-[65px] xs:mb-[78px] sm:mb-[91px] md:mb-[104px] lg:mb-[117px] xl:mb-[130px] 2xl:mb-[141px]"
    >
      <p
        className={`ml-[21px] xs:ml-[30px] sm:ml-[44px] md:ml-[58px] lg:ml-[90px] xl:ml-[114px] 2xl:ml-[137px] mb-[21px] xs:mb-[30px] sm:mb-[40px] md:mb-[50px] lg:mb-[60px] xl:mb-[70px] 2xl:mb-20 ${styles.title}`}
      >
        More from <span className="underline">{activeCard?.brandName}</span>
      </p>
      <div className="mx-[21px] xs:mx-[30px] sm:mx-[44px] md:mx-[58px] lg:mx-[90px] xl:mx-[114px] 2xl:mx-[137px]">
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-[18px] xs:gap-[20px] sm:gap-[23px] md:gap-[26px] lg:gap-[29px] xl:gap-[32px] 2xl:gap-[35px]">
          {currentItems.map((card) => (
            <ProductCard key={card.id} card={card} />
          ))}
        </div>
        {/*<div className="relative mt-4">
          <select
            className="absolute left-0 w-12 xs:w-16 mt-2 block rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            value={itemsPerPage}
            onChange={(e) => {
              setItemsPerPage(Number(e.target.value));
            }}
          >
            {[8, 12, 16].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
          <ResponsivePagination
            current={currentPage}
            total={totalPages}
            onPageChange={setCurrentPage}
            activeItemClassName="active-page-item"
          />
            </div>*/}
      </div>
    </div>
  );
}
