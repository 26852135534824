import React from 'react';

import styles from './OurService.module.css';

export default function OurService() {
  return (
    <div className={styles.serviceSpace}>
      <div className="app-container mx-auto">
        <p className={styles.title}>Our Service</p>
        <div className="flex justify-center mt-[20px]  mb-[20px] sm:mt-[60px]">
          <div className="w-[130px] sm:w-[180px] md:w-[220px] lg:w-[270px] xl:w-[350px]">
            <div className={styles.cupIcon}></div>
            <p className={styles.subtitle}>Wholesale Beverage Solutions</p>
            <p className={styles.content}>
              Bulk supply of top-tier drinks for businesses, ensuring quality
              and value in every order.
            </p>
          </div>
          <div className="w-[130px] sm:w-[180px] md:w-[220px] lg:w-[270px] xl:w-[350px] mx-0 xs:mx-[20px] sm:mx-[30px] md:mx-[40px] lg:mx-[80px] xl:mx-[100px] 2xl:mx-[136px]">
            <div className={styles.earthIcon}></div>
            <p className={styles.subtitle}>Global Premium Selections</p>
            <p className={styles.content}>
              An exquisite collection of premium drinks from around the world,
              curated for discerning tastes.
            </p>
          </div>
          <div className="w-[130px] sm:w-[180px] md:w-[220px] lg:w-[270px] xl:w-[350px]">
            <div className={styles.hatIcon}></div>
            <p className={styles.subtitle}>Michelin-Grade Partnerships</p>
            <p className={styles.content}>
              Proudly collaborating with Michelin-starred restaurants to deliver
              exceptional beverage experiences.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
