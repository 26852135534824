import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

import styles from './BrandSlider.module.css';

const images = [
  { id: 1, src: '/images/copenhagen-sparkling-logo.png', alt: 'copenhagen sparkling-tea' },
  { id: 2, src: '/images/sparkling-tea-saicho.png', alt: 'saicho-tea' },
  { id: 3, src: '/images/copenhagen-sparkling-logo.png', alt: 'copenhagen sparkling-tea' },
  { id: 4, src: '/images/sparkling-tea-saicho.png', alt: 'saicho-tea' },
  { id: 5, src: '/images/copenhagen-sparkling-logo.png', alt: 'copenhagen sparkling-tea' },
  { id: 6, src: '/images/sparkling-tea-saicho.png', alt: 'saicho-tea' },
  { id: 7, src: '/images/copenhagen-sparkling-logo.png', alt: 'copenhagen sparkling-tea' },
  { id: 8, src: '/images/sparkling-tea-saicho.png', alt: 'saicho-tea' },
  { id: 9, src: '/images/copenhagen-sparkling-logo.png', alt: 'copenhagen sparkling-tea' },
  { id: 10, src: '/images/sparkling-tea-saicho.png', alt: 'saicho-tea' },
  { id: 11, src: '/images/copenhagen-sparkling-logo.png', alt: 'copenhagen sparkling-tea' },
  { id: 12, src: '/images/sparkling-tea-saicho.png', alt: 'saicho-tea' },
];

export default function BrandSlider() {
  return (
    <div className="app-container mx-auto pt-[18px] sm:pt-[80px] pb-[27px] sm:pb-[92px] ">
      <p className={styles.title}>Top Brands</p>
      <Swiper
        slidesPerView={3}
        spaceBetween={12}
        breakpoints={{
          400: {
            slidesPerView: 4,
            spaceBetween: 12,
          },
          640: {
            slidesPerView: 4,
            spaceBetween: 12,
          },
          767: {
            slidesPerView: 5,
            spaceBetween: 12,
          },
          1024: {
            slidesPerView: 7,
            spaceBetween: 14,
          },
          1280: {
            slidesPerView: 8,
            spaceBetween: 16,
          },
        }}
        loop={true}
        className="home-brand-swiper"
      >
        {images.map((image) => (
          <SwiperSlide key={image.id}>
            <img
              src={image.src}
              alt={`Slide ${image.id}`}
              className="mx-auto h-[61px] sm:h-[120px]"
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
