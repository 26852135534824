import React from 'react';

import BrandSlider from '../../components/BrandSlider';
import styles from './TopBrands.module.css';

export default function TopBrands() {
  return (
    <div className={styles.brandSpace}>
      <BrandSlider />
    </div>
  );
}
