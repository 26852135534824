import React from 'react';

import BrandSlider from '../../components/BrandSlider';

export default function Brand() {
  return (
    <div className="bg-none sm:bg-white">
      <BrandSlider />
    </div>
  );
}
