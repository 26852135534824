import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './Menu.module.css';

export default function Menu({
  scrollToHome,
  scrollToPremium,
  scrollToContact,
}) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleToggleMenu = () => {
    setOpen(!open);
  };

  return (
    <div
      className="app-container mx-auto relative flex pt-4"
      onClick={() => {
        if (open) {
          setOpen(false);
        }
      }}
    >
      <button
        className="block sm:hidden absolute z-10 transition-all w-8 max-w-8 h-8 max-h-8 rounded-lg hover:shadow-lg focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none mt-[5px] ml-[23px]"
        type="button"
        onClick={handleToggleMenu}
      >
        <img src="/icons/menu.svg" alt="menu" className="pl-[5px]" />
      </button>
      <img
        src="/icons/logo.svg"
        alt="logo"
        className="relative mx-auto sm:ml-[30px] md:ml-[48px] lg:ml-[64px] xl:ml-[80px] 2xl:ml-[99px] sm:mr-0 w-[76px] sm:w-[145px] cursor-pointer"
        onClick={() => navigate('/')}
      />
      <ul className="hidden sm:flex list-none justify-center items-center ml-auto sm:mr-[30px] md:mr-[50px] lg:mr-[70px] xl:mr-[150px] 2xl:mr-[230px] relative">
        <li
          className={`${styles.menuItem} mr-8`}
          onClick={() => {
            setOpen(false);
            scrollToHome();
          }}
        >
          Home
        </li>
        <li
          className={`${styles.menuItem} mr-8`}
          onClick={() => {
            setOpen(false);
            scrollToPremium();
          }}
        >
          Our Premium Selection
        </li>
        <li
          className={styles.menuItem}
          onClick={() => {
            setOpen(false);
            scrollToContact();
          }}
        >
          Contact Us
        </li>
      </ul>

      <div
        className={`absolute top-14 z-20 ml-4 bg-white rounded-lg shadow w-44 dark:bg-gray-700 ${
          open ? 'block sm:hidden' : 'hidden'
        }`}
      >
        <ul className="px-2 py-2 text-sm text-gray-700 dark:text-gray-200">
          <li
            className={styles.menuItem}
            onClick={() => {
              setOpen(false);
              scrollToHome();
            }}
          >
            Home
          </li>
          <li
            className={styles.menuItem}
            onClick={() => {
              setOpen(false);
              scrollToPremium();
            }}
          >
            Our Premium Drinks
          </li>
          <li
            className={styles.menuItem}
            onClick={() => {
              setOpen(false);
              scrollToContact();
            }}
          >
            Contact Us
          </li>
        </ul>
      </div>
    </div>
  );
}
