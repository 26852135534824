import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Scrollbar } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/scrollbar';

import { ProductContext } from '../../context/ProductContext';
import styles from './PremiumSlider.module.css';

const images = [
  {
    id: 1,
    src: '/images/alcohol.jpg',
    alt: 'PREMIUM ALCOHOL',
    type: 'Alcohol',
  },
  { id: 2, src: '/images/tea.png', alt: 'PREMIUM TEAS', type: 'Tea' },
  { id: 3, src: '/images/juice.png', alt: 'PREMIUM JUICE', type: 'Juice' },
  {
    id: 4,
    src: '/images/alcohol.jpg',
    alt: 'PREMIUM ALCOHOL',
    type: 'Alcohol',
  },
  { id: 5, src: '/images/tea.png', alt: 'PREMIUM TEAS', type: 'Tea' },
  { id: 6, src: '/images/juice.png', alt: 'PREMIUM JUICE', type: 'Juice' },
];

export default function PremiumSlider({ premiumRef }) {
  const navigate = useNavigate();
  const { selectFilter } = useContext(ProductContext);

  return (
    <div
      className={`pt-[36px] sm:pt-[240px] pb-[0px] sm:pb-[70px] ${styles.sliderArea}`}
    >
      <div className="app-container mx-auto premium-slider">
        <p ref={premiumRef} className={`${styles.title} pb-1 sm:pb-[45px]`}>
          Our Premium Selection
        </p>
        <Swiper
          slidesPerView={3}
          spaceBetween={8}
          breakpoints={{
            430: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
            640: {
              slidesPerView: 4,
              spaceBetween: 18,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 24,
            },
          }}
          loop={true}
          scrollbar={{
            hide: true,
          }}
          modules={[Scrollbar]}
          className="home-premium-swiper"
        >
          {images.map((image) => (
            <SwiperSlide key={image.id}>
              <img
                src={image.src}
                alt={`Slide ${image.id}`}
                className="w-[115px] xs:w-[130px] sm:w-[138px] md:w-[170px] lg:w-[230px] xl:w-[280px] h-[115px] xs:h-[130px] sm:h-[138px] md:h-[170px] lg:h-[230px] xl:h-[280px]"
              />
              <div className={`text-center cursor-pointer ${styles.cardInfo}`}>
                <p
                  className={`mb-0.5 sm:mb-1 md:md-1.5 lg:md-2 ${styles.subtitle}`}
                >
                  {image.alt}
                </p>
                <p
                  className={`flex items-center justify-center ${styles.viewProduct}`}
                  onClick={() => {
                    selectFilter(image.type);
                    navigate('/products');
                  }}
                >
                  View all products
                  <img
                    src="/icons/arrow-right-green.svg"
                    className="w-[8.64px] xs:w-[10px] sm:w-[14px] md:w-[18px] lg:w-[20px] xl:w-[22px] h-[8.64px] xs:h-[10px] sm:h-[14px] md:h-[18px] lg:h-[20px] xl:h-[22px]"
                    alt={`arrow ${image.id}`}
                  />
                </p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}
