import React, { useContext } from 'react';

import { ProductContext } from '../../context/ProductContext';
import styles from './OtherPremium.module.css';

export default function OtherPremium({ premiumRef, scrollToProduct }) {
  const { selectFilter } = useContext(ProductContext);

  return (
    <div
      className={`pt-[36px] sm:pt-[56px] lg:pt-[76px] pb-[36px] xs:pb-[70px] sm:pb-[130px] lg:pb-[190px] ${styles.premiumArea}`}
    >
      <div className="app-container mx-auto">
        <p
          ref={premiumRef}
          className={`${styles.title} text-center pb-6 sm:pb-9`}
        >
          Other Premium Drinks
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-0 sm:gap-x-4 md:gap-x-5 lg:gap-x-6">
          <div className="relative mx-auto sm:ml-auto sm:mr-0 mb-[44px] sm:mb-0">
            <img
              src="/images/alcohol.jpg"
              alt="alcohol"
              className="h-[270px] xs:h-[300px] sm:h-[290px] md:h-[350px] lg:h-[500px] xl:h-[600px] 2xl:h-[700px]"
            />
            <div
              className={`text-center cursor-pointer absolute ${styles.cardInfo}`}
            >
              <p
                className={`mb-0.5 sm:mb-1 md:mb-1.5 lg:mb-2 xl:mb-2.5 ${styles.subtitle}`}
              >
                PREMIUM ALCOHOL
              </p>
              <p
                className={`flex items-center justify-center ${styles.viewProduct}`}
                onClick={() => {
                  selectFilter('Alcohol');
                  scrollToProduct();
                }}
              >
                View all product
                <img
                  src="/icons/arrow-right-green.svg"
                  className="w-[8.64px] xs:w-[10px] sm:w-[14px] md:w-[18px] lg:w-[20px] xl:w-[22px] h-[8.64px] xs:h-[10px] sm:h-[14px] md:h-[18px] lg:h-[20px] xl:h-[22px]"
                  alt="arrow"
                />
              </p>
            </div>
          </div>
          <div className="relative mx-auto sm:ml-0 sm:mr-auto">
            <img
              src="/images/juice.svg"
              alt="juice"
              className="h-[270px] xs:h-[300px] sm:h-[290px] md:h-[350px] lg:h-[500px] xl:h-[600px] 2xl:h-[700px]"
            />
            <div
              className={`text-center cursor-pointer absolute ${styles.cardInfo}`}
            >
              <p
                className={`mb-0.5 sm:mb-1 md:mb-1.5 lg:mb-2 xl:mb-2.5 ${styles.subtitle}`}
              >
                PREMIUM JUICE
              </p>
              <p
                className={`flex items-center justify-center ${styles.viewProduct}`}
                onClick={() => {
                  selectFilter('Juice');
                  scrollToProduct();
                }}
              >
                View all product
                <img
                  src="/icons/arrow-right-green.svg"
                  className="w-[8.64px] xs:w-[10px] sm:w-[14px] md:w-[18px] lg:w-[20px] xl:w-[22px] h-[8.64px] xs:h-[10px] sm:h-[14px] md:h-[18px] lg:h-[20px] xl:h-[22px]"
                  alt="arrow"
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
