import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import Menu from './Menu';
import Intro from './Intro';
import MainProduct from './MainProduct';
import Brand from './Brand';
import Footer from '../../components/Footer';
import OtherPremium from './OtherPremium';
import ContactUs from './ContactUs';

export default function Products() {
  const { pathname } = useLocation();
  const homeRef = useRef(null);
  const premiumRef = useRef(null);
  const productRef = useRef(null);
  const contactRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const scrollToHome = () => {
    homeRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToPremium = () => {
    premiumRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToProduct = () => {
    productRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToContact = () => {
    contactRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <Helmet>
        <title>Nubeverage - Products</title>
        <meta name="description" content="nubeverage products page" />
      </Helmet>
      <div className="full-container product-area">
        <Menu
          scrollToHome={scrollToHome}
          scrollToPremium={scrollToPremium}
          scrollToContact={scrollToContact}
        />
        <Intro homeRef={homeRef} scrollToContact={scrollToContact} />
        <MainProduct productRef={productRef} />
        <Brand />
        <OtherPremium
          premiumRef={premiumRef}
          scrollToProduct={scrollToProduct}
        />
        <ContactUs contactRef={contactRef} />
        <Footer
          scrollToHome={scrollToHome}
          scrollToPremium={scrollToPremium}
          scrollToAbout={scrollToContact}
        />
      </div>
    </>
  );
}
