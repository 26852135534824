import React, { useEffect, useState } from 'react';

import styles from './Menu.module.css';

const images = [
  '/images/home-bg-big.jpg',
  '/images/home-bg-big-2.jpg',
  '/images/home-bg-big-3.jpg',
];

const smallImages = [
  '/images/home-bg-small.png',
  '/images/home-bg-small-2.png',
  '/images/home-bg-small-3.png',
];

export default function Menu({
  scrollToHome,
  scrollToPremium,
  scrollToContact,
}) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const handleToggleMenu = () => {
    setOpen(!open);
  };

  const currentImage =
    window.innerWidth > 639 ? images[activeIndex] : smallImages[activeIndex];

  return (
    <div
      className={`relative ${styles.menuArea}`}
      onClick={() => {
        if (open) {
          setOpen(false);
        }
      }}
    >
      <div className="app-container mx-auto pt-[15px] sm:pt-[22px]">
        <div
          className={styles.topBg}
          style={{
            backgroundImage: `url(${currentImage})`,
          }}
        ></div>
        <button
          className="block sm:hidden absolute z-10 transition-all w-8 max-w-8 h-8 max-h-8 rounded-lg hover:shadow-lg focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none mt-[5px] ml-[23px]"
          type="button"
          onClick={handleToggleMenu}
        >
          <img src="/icons/menu.svg" alt="menu" className="pl-[5px]" />
        </button>
        <img
          src="/icons/logo.svg"
          alt="logo"
          className="mx-auto z-10 relative w-[76px] xs:w-[100px] sm:w-[145px] cursor-pointer"
        />
        <ul className="hidden sm:flex list-none justify-center z-10 relative">
          <li
            className={`${styles.menuItem} mr-8`}
            onClick={() => {
              setOpen(false);
              scrollToHome();
            }}
          >
            Home
          </li>
          <li
            className={`${styles.menuItem} mr-8`}
            onClick={() => {
              setOpen(false);
              scrollToPremium();
            }}
          >
            Our Premium Drinks
          </li>
          <li
            className={styles.menuItem}
            onClick={() => {
              setOpen(false);
              scrollToContact();
            }}
          >
            Contact Us
          </li>
        </ul>

        <div
          className={`absolute top-14 z-20 ml-4 bg-white rounded-lg shadow w-44 dark:bg-gray-700 ${
            open ? 'block sm:hidden' : 'hidden'
          }`}
        >
          <ul className="px-2 py-2 text-sm text-gray-700 dark:text-gray-200">
            <li
              className={styles.menuItem}
              onClick={() => {
                setOpen(false);
                scrollToHome();
              }}
            >
              Home
            </li>
            <li
              className={styles.menuItem}
              onClick={() => {
                setOpen(false);
                scrollToPremium();
              }}
            >
              Our Premium Drinks
            </li>
            <li
              className={styles.menuItem}
              onClick={() => {
                setOpen(false);
                scrollToContact();
              }}
            >
              Contact Us
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
