import React, { useRef } from 'react';
import { Helmet } from 'react-helmet-async';

import Menu from './Menu';
import Intro from './Intro';
import PremiumSlider from './PremiumSlider';
import TopBrands from './TopBrands';
import OurService from './OurService';
import CollaborationRestaurant from './CollaborationRestaurant';
import ContactUs from './ContactUs';
import AboutUs from './AboutUs';
import Footer from '../../components/Footer';

export default function Home() {
  const homeRef = useRef(null);
  const premiumRef = useRef(null);
  const contactRef = useRef(null);
  const aboutRef = useRef(null);

  const scrollToHome = () => {
    homeRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToPremium = () => {
    premiumRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToContact = () => {
    contactRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToAbout = () => {
    aboutRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <Helmet>
        ‍<title>Nubeverage - Home</title>‍
        <meta name="description" content="nubeverage home page" />
      </Helmet>
      <div className="full-container">
        <Menu
          scrollToHome={scrollToHome}
          scrollToPremium={scrollToPremium}
          scrollToContact={scrollToContact}
        />
        <Intro homeRef={homeRef} scrollToContact={scrollToContact} />
        <PremiumSlider premiumRef={premiumRef} />
        <TopBrands />
        <OurService />
        <CollaborationRestaurant />
        <ContactUs contactRef={contactRef} />
        <AboutUs aboutRef={aboutRef} />
        <Footer
          scrollToHome={scrollToHome}
          scrollToPremium={scrollToPremium}
          scrollToAbout={scrollToAbout}
        />
      </div>
    </>
  );
}
