import React from 'react';

import styles from './Intro.module.css';

export default function Intro({ homeRef }) {
  return (
    <div ref={homeRef} className="app-container mx-auto">
      <div className="relative z-10 mt-[30px] xs:mt-[50px] sm:mt-[70px] md:mt-[90px] lg:mt-[120px] xl:mt-[150px] 2xl:mt-[182px] mx-0 xs:mx-[20px] sm:mx-[45px] md:mx-[70px] lg:mx-[95px] xl:mx-[120px] 2xl:mx-[146px]">
        <div className={styles.title}>
          <p>Unique&nbsp;</p>
          <p>Beverages</p>
        </div>
        <div className={`${styles.bottomTitle} w-40 xs:w-60 md:w-full`}>
          <p>Access to the Canada’s&nbsp;</p>
          <p>Premium Drinks</p>
        </div>
      </div>
    </div>
  );
}
