import React, { createContext, useState } from 'react';

export const ProductContext = createContext();

export const ProductProvider = ({ children }) => {
  const [filter, setFilter] = useState('All');

  const selectFilter = (value) => {
    setFilter(value);
  };

  return (
    <ProductContext.Provider value={{ filter, selectFilter }}>
      {children}
    </ProductContext.Provider>
  );
};
